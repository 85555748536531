<template>
    <section class="kyukai-main">
        <v-container class="fill-height" fluid>
            <v-row class="kyukai-main__contents">
                <v-col cols="12" sm="8" md="4" class="kyukai-main__contents-text" v-if="postComplete">

                    <v-card flat color="transparent">
                        <v-card-text>
                            <div class="section__title-text">お申込みが完了しました</div>

                            <div class="section__title-text" style="margin-top:10%;"><a :href="topPageUrl">トップページに戻る</a></div>

                            <div class="section__title-text" style="margin-top:10%;">(お申込み内容、ご請求内容は以下になります)</div>

                            <div class="section__title-text" style="margin-top:10%;">◇ご請求内容</div>
                            <div class="section__title-text" style="margin-top:10%;">
                                <v-row>
                                    <v-simple-table style="width:100%;">
                                        <template v-slot:default>
                                            <tbody>
                                                <tr v-if="nyukai.selectContents != 1">
                                                    <td><p class="v-label theme--light usedetail_text">入会金</p></td>
                                                    <td><p class="v-label theme--light usedetail_text" style="text-align: right;">{{nyukai.subtotalAmount_EF}}</p></td>
                                                </tr>
                                                <tr v-if="nyukai.selectContents != 1">
                                                    <td><p class="v-label theme--light usedetail_text">年会費</p></td>
                                                    <td><p class="v-label theme--light usedetail_text" style="text-align: right;">{{nyukai.subtotalAmount_AF}}</p></td>
                                                </tr>
                                                <tr v-if="nyukai.selectContents != 1">
                                                    <td><p class="v-label theme--light usedetail_text">月会費(2か月分)</p></td>
                                                    <td><p class="v-label theme--light usedetail_text" style="text-align: right;">{{nyukai.subtotalAmount_MF}}</p></td>
                                                </tr>
                                                <tr v-if="nyukai.selectContents != 2">
                                                    <td><p class="v-label theme--light usedetail_text">体験費用</p></td>
                                                    <td><p class="v-label theme--light usedetail_text" style="text-align: right;">{{nyukai.subtotalAmount_TF}}</p></td>
                                                </tr>
                                                <tr v-if="nyukai.selectContents != 1">
                                                    <td><p class="v-label theme--light usedetail_text">水着</p></td>
                                                    <td><p class="v-label theme--light usedetail_text" style="text-align: right;">{{nyukai.subtotalAmount_SS}}</p></td>
                                                </tr>
                                                <tr v-if="nyukai.selectContents != 1">
                                                    <td><p class="v-label theme--light usedetail_text">キャップ</p></td>
                                                    <td><p class="v-label theme--light usedetail_text" style="text-align: right;">{{nyukai.subtotalAmount_SC}}</p></td>
                                                </tr>
                                                <tr v-if="nyukai.selectContents != 1">
                                                    <td><p class="v-label theme--light usedetail_text">バッグ</p></td>
                                                    <td><p class="v-label theme--light usedetail_text" style="text-align: right;">{{nyukai.subtotalAmount_SB}}</p></td>
                                                </tr>
                                                <tr v-if="nyukai.selectContents != 1">
                                                    <td><p class="v-label theme--light usedetail_text">バス協力金(2か月分)</p></td>
                                                    <td><p class="v-label theme--light usedetail_text" style="text-align: right;">{{nyukai.subtotalAmount_BF}}</p></td>
                                                </tr>
                                                <tr v-if="nyukai.selectContents != 1 && $store.state.coupon.discountFlag && Boolean($store.state.discountValue)">
                                                    <td><p class="v-label theme--light usedetail_text">クーポン割引</p></td>
                                                    <td><p class="v-label theme--light usedetail_text" style="text-align: right;">-{{$store.state.discountValue}}</p></td>
                                                </tr>                                                
                                                <tr>
                                                    <td><p class="v-label theme--light usedetail_text">合計金額</p></td>
                                                    <td><p class="v-label theme--light usedetail_text" style="text-align: right;">{{nyukai.totalAmount}}</p></td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-row>

                            </div>
                            <div class="section__title-text" style="margin-top:10%;">◇お申込内容</div>

                            <el-form label-width="150px" style="margin-top:10%;width:100%;">
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="お申込内容:">
                                            <p class="confirm_text">{{nyukai.selectContents == 1? '体験教室' : nyukai.selectContents == 2? '入会申込' : '体験教室&入会申込'}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="ご利用店舗:">
                                            <p class="confirm_text">{{nyukai.storeName}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24" v-if="nyukai.selectContents != 2">
                                        <el-form-item label="体験日付:">
                                            <p class="confirm_text">{{getDisplayDateTime(nyukai.datetimeStart_trial)}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="入会週選択:">
                                            <p class="confirm_text">{{nyukai.numWithinAWeek == 1? '週1':'週2'}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row v-if="nyukai.selectContents != 1">
                                    <el-col :span="24">
                                        <el-form-item label="1日目:">
                                            <p class="confirm_text">{{getDisplayLessonDate(nyukai.datetimeStart_day1,nyukai.datetimeEnd_day1)}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row v-if="nyukai.selectContents != 1">
                                    <el-col :span="24">
                                        <el-form-item label="バス1日目:">
                                            <p class="confirm_text">{{getBusStatus(nyukai.statusBus_day1)}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row v-if="nyukai.selectContents != 1 && nyukai.numWithinAWeek == 2">
                                    <el-col :span="24">
                                        <el-form-item label="2日目:">
                                            <p class="confirm_text">{{getDisplayLessonDate(nyukai.datetimeStart_day2,nyukai.datetimeEnd_day2)}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row v-if="nyukai.selectContents != 1 && nyukai.numWithinAWeek == 2">
                                    <el-col :span="24">
                                        <el-form-item label="バス2日目:">
                                            <p class="confirm_text">{{getBusStatus(nyukai.statusBus_day2)}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24" v-if="nyukai.selectContents != 1">
                                        <el-form-item label="初回来店日付:">
                                            <p class="confirm_text">{{getDisplayDateTime(nyukai.datetimeStart_visit)}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                            </el-form>


                            <div class="section__title-text" style="margin-top:10%;">◇お客様情報</div>

                            <el-form label-width="150px" style="margin-top:10%;">
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="お子様のお名前:">
                                            <p class="confirm_text">{{nyukai.memberName}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="フリガナ:">
                                            <p class="confirm_text">{{nyukai.memberKanaName}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="お誕生日:">
                                            <p class="confirm_text">{{getDisplayBirythdayToJapanese(nyukai.dateBirthDay)}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="年齢:">
                                            <p class="confirm_text">{{nyukai.age + '歳'}}</p>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-form-item label="性別:">
                                            <p class="confirm_text">{{nyukai.sex}}</p>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-form-item label="連絡可能時間帯:">
                                            <p class="confirm_text">{{nyukai.contactableHourName}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="保護者氏名:">
                                            <p class="confirm_text">{{nyukai.parentName}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="郵便番号:">
                                            <p class="confirm_text">{{nyukai.zipCode}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="住所:">
                                            <p class="confirm_text">{{nyukai.address}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="電話番号:">
                                            <p class="confirm_text">{{nyukai.cellPhone}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="メールアドレス:">
                                            <p class="confirm_text">{{nyukai.phoneMail}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="現在の学校名:">
                                            <p class="confirm_text">{{nyukai.schoolName == ''? '未回答': nyukai.schoolName}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="学年:">
                                            <p class="confirm_text">{{nyukai.schoolYear== ''? '未回答': nyukai.schoolYear}}</p>
                                        </el-form-item>
                                    </el-col>

                                </el-row>

                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="スイミング経験:">
                                            <p class="confirm_text">{{nyukai.swimmingExp == undefined? '未回答': nyukai.swimmingExp == 0? '無し':'有り'}}</p>
                                        </el-form-item>
                                    </el-col>

                                </el-row>
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="泳力:">
                                            <p class="confirm_text">{{nyukai.swimmingAbility}}</p>
                                        </el-form-item>
                                    </el-col>

                                </el-row>

                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="水着サイズ:">
                                            <p class="confirm_text">{{nyukai.itemName_SS}}</p>
                                        </el-form-item>
                                    </el-col>

                                </el-row>


                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="お支払い方法:">
                                            <p class="confirm_text">{{nyukai.payCatCD == 0? '現金':'クレジットカード'}}</p>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-form>
                            <div class="section__title-text" style="margin-top:10%;"><a :href="topPageUrl">トップページに戻る</a></div>

                        </v-card-text>
                    </v-card>

                </v-col>
            </v-row>


        </v-container>
    </section>
</template>

<script>
    export default {

        components: {
        },

        data: () => ({
            postComplete: false,
            topPageUrl: "https://www.sports-renaiss.jp/swimming/sales-info/kids-taisaku/",
            nyukai: {},


        }),

        // インスタンスが作成された後に同期的に呼ばれる

        created: function () {


            /*
            //申込内容入力が完了してなければ戻る
            if (!this.$store.state.isNyukaiReservationFormed) {
                this.$router.push('/nyukai');
                return false;
            }
            //お客様情報入力が完了してなければ戻る
            if (!this.$store.state.isNyukaiCustomerFormed) {
                this.$router.push('/nyukaiCustomer');
                return false;
            }
            //入会がある申込内容を選択していて、確認画面入力が完了してなければ戻る
            if (this.$store.state.isSelectedJoin) {
                if (!this.$store.state.isNyukaiConfirmFormed) {
                    this.$router.push('/nyukaiConfirm');
                    return false;
                }
            }
            //支払情報入力が完了してなければ戻る
            if (!this.$store.state.isNyukaiPaymentFormed) {
                this.$router.push('/nyukaiPayment');
                return false;
            }
           */
            window.scrollTo(0, 0);
            var postdata = this.$store.state.nyukai;

            /*
            //直接confirmに来たら戻す
            if (Boolean(postdata.totalAmount) == false) 
            {
                window.location.href = this.$store.state.projectStarturl;
            }
            */

            console.log("postdata is");
            console.log(postdata);
            
            var ID = this.$store.state.nyukaiObjectID

            this.$set(this, "nyukai", this.$store.state.nyukai);
            //postできるのは一回きり
            //if (!this.$store.state.isPostData) {

                this.axios.post(this.$store.state.apiUrl + "/api/KidsSwimmingEnrollment/postNewSwimmingMemberWithSQLQuery", postdata)
                    .then(response => {
                        console.log(response);

                        //alert(response.data); //デバッグ用

                        var success = {
                            "kidsSwimLogId": ID,
                            "jsonObject": JSON.stringify(postdata),
                            "boolian": response.data 
                        };

                        this.$store.commit('updateIsComplete', true);


                        this.axios.post(this.$store.state.apiUrl + "/api/KidsSwimmingEnrollmentLogs/postObject", success)

                            .then(response => {


                                console.log(this.$store.state);
                                this.$store.commit('updateIsPostData', true);


                            })
                            .catch(response => console.log(response))


                    })
                    .catch(response => console.log(response))
            //}


            this.postComplete = true;


        },



        methods: {
            getDisplayBirythdayToJapanese(birthday) {
                return this.$moment(birthday).format('YYYY年MM月DD日');
            },
            getDisplayDateTime(datetime) {
                return this.$moment(datetime).format('YYYY/MM/DD HH:mm');
            },
            getDisplayLessonDate(start, end) {
                return ["日", "月", "火", "水", "木", "金", "土"][new Date(start).getDay()]
                    + "：" + this.$moment(start).format('HH:mm') + " ～ " + this.$moment(end).format('HH:mm')
            },
            getBusStatus(code) {
                if (code == -1) {
                    return "無し"
                } else if (code == 0) {
                    return "行き";
                } else if (code == 1) {
                    return "帰り";
                } else if (code == 2) {
                    return "往復";
                }
            }


        },
    }
</script>


<style lang="scss" scoped>
    .confirm_text {
        text-align: left;
        font-weight: bold;
        font-size: 17px;
    }

    .kyukai-main__contents {
        justify-content: center;
        text-align: center;
        &-text

    {
        word-wrap: break-word;
    }

    }

    .button {
        text-align: center;
        margin-top: 10px;
    }

    .home-title__contents {
        margin-top: 5px;
        justify-content: center;
        text-align: center;
    }
</style>