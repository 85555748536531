<template>
    <section class="kyukai">
        <AppHeader :header-text="headerText" />
        <v-content>
            <ConfirmMain />
        </v-content>
        <AppFooter />
    </section>
</template>

<script>
import ConfirmMain from '@/components/ConfirmMain.vue';
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
  name: 'Kyukai',
  components: {
    ConfirmMain,
    AppHeader,
    AppFooter,
  },

  data: () => ({
    headerText: '申込完了',
  }),

}
</script>

<style lang="scss" scoped>
    .kyukai {
        padding-bottom: 50px;
    }
</style>